ion-item-sliding.track-one ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}
ion-item-sliding.track-two ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}
ion-item-sliding.track-children ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}
ion-item-sliding.track-stage ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}
ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}
ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}
ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}
ion-item-sliding.track-field ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}
ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}
ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}
