.about-header {
  background-color: #A3B9A4;
  width: 100%;
  min-height: 150px;
  text-align: center;
}
.about-header img {
  min-height: 115px;
}
.about-info p {
  color: #fff;
  text-align: left;
}
.about-info ion-icon {
  width: 20px;
}
.md .about-info [text-right],
.wp .about-info [text-right] {
  margin-right: 0;
}
a:link, a:visited {
  color: #A0A0F0
}

h1 {
  text-align: center;
}
